let $openBtn = document.querySelector('.header__hamburger');
let $closeBtn = document.querySelector('.header__close');
let $navigation = document.querySelector('.header__navigation');
let $dropdownBtn = document.querySelector('.header__toggler');
let $dropdown = document.querySelector('.header__dropdown');


let $header__ul = document.querySelector('.header__ul');

const open = () => {
    $navigation.classList.add('header__navigation--visible');
};

const close = () => {
    $navigation.classList.remove('header__navigation--visible');
};

const toggleDropdown = (e) => {
    const clickedButton = e.target.closest('button');
    const chosenSubMenu = clickedButton.nextElementSibling;
    
    clickedButton.classList.toggle('header__toggler-rotated');
    chosenSubMenu.classList.toggle('header__dropdown--visible');
}

$openBtn.addEventListener('click', open);
$closeBtn.addEventListener('click', close);
$header__ul.addEventListener('click', toggleDropdown);




